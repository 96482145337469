import React, { useRef, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Box, Grid } from '@material-ui/core';
import inViewport from '../../../util/inViewport';
import { debounce } from "lodash";

import Slider from "react-slick";
import { theme } from '../../../themes/default-theme';
import { RespImg, TabsCurved } from '../../ui';
import Text from '../../typography'
import { ReactSVG } from 'react-svg';

import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';

const Wrapper = styled.div`

`

const Container = styled.div`

`

const TitleContainer = styled.div`
  
`

const TextHandle = styled(props => {
  return <Text {...props} />
})`
  max-width: 815px;
  margin-left: auto;
  margin-right: auto;
  ${props => props.background == 'dark' && `
    &h3 {
      color: white;
    }
  `}
`

const TabsContainer = styled.div`
  margin: 30px auto;
  & .MuiTabs-flexContainer {
    justify-content: center;
    margin: auto;
    width: max-content;
  }
  .MuiTab-wrapper {
    white-space: nowrap;
  }
`

const SliderContainer = styled.div`
  .slick-slider {
    padding-bottom: 60px;

    ${theme.breakpoints.down('sm')} {
      padding-bottom: 30px;
    }
  }

  .slick-slide {
    visibility: hidden;

    &.slick-active {
      visibility: visible;
    }
  }

  .slick-dots {
    list-style-type: none;
    padding: 0;
  }

  .slick-dots {
    display: flex !important;
    gap: 13px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;


    > li {
      position: relative;
      z-index: 116;
    }
    li {
      &.slick-next,
      &.slick-prev {
          ${theme.breakpoints.down('md')} {
              display: none;
          }
          button {
              background-color: transparent;
              padding: 0;
          }
    
          svg {
              height: 10px;
              width: auto;
              position: relative;
              top: -1px;
              [stroke] {
                  stroke: #758094;
              }
          }
            button {
              cursor: pointer;
              width: 12px;
              height: 12px;
              border-radius: 12px;
              border: none;
              font-size: 0;
          }
      }
    }
  }
  

`
const EnrichedContainer = styled.div`
  position: relative;
  ${theme.breakpoints.up('md')} {
    height: 750px;
  }
  ${theme.breakpoints.down('sm')} {
    height: 300px;
  }
  ${theme.breakpoints.down('xs')} {
    height: 176px;
  }
`

const EnrichedImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  picture, picture > img {
    display: block;
    width: 100%;
    object-fit: cover;
    ${theme.breakpoints.down('sm')} {
      height: auto;
    }
    ${theme.breakpoints.down('xs')} {
      height: 176px;
    }
  }
`

const EnrichedVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
`

const IFrame = styled.iframe`
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const SliderDot = styled.div`
  background-color: ${theme.palette.middleGrey};
  border-radius: 24.5px;
  width: 12px;
  height: 12px;
  cursor: pointer;

  .slick-active & {
    background-color: ${theme.palette.vividBlue};
  }
`

export const EnrichedImageGallery = props => {
  const {
    background,
    title,
    pretitle,
    data,
    colourTheme
  } = props;

  const [componentInView, setComponentInView] = useState(false);

  const componentRef = useRef();

  const listener = (e) => {
      const show = inViewport(componentRef.current, 500);
      if (show === true) {
        setComponentInView(true)
      }
  };

  useEffect(() => {
      listener();
      const delay = 200;
      window.addEventListener('scroll', debounce(listener, delay));
      return () => {
        window.removeEventListener('scroll', listener);
      };
  }, [])



  const tabData = data.map(function (value) {
    return {
      title: value.category,
      tour: value.tour
    }
  }).filter(item => item.title);

  const [activeTab, setActiveTab] = useState(0);

  const sliderRef = useRef();
  const handleTabChange = index => {
    setActiveTab(index);
    sliderRef.current.slickGoTo(0);
  }

  const handleArrowClick = (type) => {
    if (type === 'prev') {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  }

  var settings = {
    customPaging: function (i) {
      return (
        <SliderDot />
      );
    },
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: (dots) => {
      return (
        <ul className="slick-dots">
          <li className="slick-arrow slick-prev"><button onClick={() => handleArrowClick('prev')}><ReactSVG src={chevronLeft} /></button></li>
          {dots}
          <li className="slick-arrow slick-next"><button onClick={() => handleArrowClick('next')}><ReactSVG src={chevronRight} /></button></li>
        </ul>
      )
    },
  };

  const fontColour = colourTheme && colourTheme === 'dark' ? 'white' : 'charcoal';

  return (
    <Wrapper>
      <Container background={background && background}>
        <Grid container spacing={0} direction={'row'}>
          <Grid item xs={12}>
            {(pretitle || title) &&
              <TitleContainer>
                <Box px={2}>
                  {pretitle &&
                    <TextHandle variant="subTitle" background={background && background} colour={'vividBlue'} uppercase={true} align="center">{pretitle}</TextHandle>
                  }
                  {title &&
                    <TextHandle element="h3" variant="h2" weight={800} background={background && background} colour={fontColour} uppercase={true} align="center">{ReactHtmlParser(title)}</TextHandle>
                  }
                </Box>
              </TitleContainer>
            }
            <Box px={2}>
              <TabsContainer>
                {(tabData && tabData.length > 1) &&
                  <TabsCurved
                    data={tabData}
                    variant="scrollable"
                    onChange={(e) => handleTabChange(e)}
                    background={colourTheme}
                    neverNative={true}
                  />
                }
              </TabsContainer>
            </Box>

          </Grid>
        </Grid>
        {data &&
          <SliderContainer ref={componentRef}>
            <Slider {...settings} ref={sliderRef}>
              {
                data[activeTab].assets.map((item, index) => {
                  return (
                    <EnrichedContainer key={index} >
                      {item.image ?
                        <EnrichedImage>
                          <RespImg
                            {...item.image}
                            fluid={false}
                          />
                        </EnrichedImage>
                        : 
                        componentInView &&
                        <>
                          {item.video ?
                            <>
                              <EnrichedVideo muted autoPlay loop playsInline key={undefined}>
                                <source src={item.video} />
                              </EnrichedVideo>
                            </>
                            : item.iframe &&
                            <IFrame scrolling="no" src={item.iframe + '&play=1&qs=1&wh=0&brand=1&dh=0&title=0&search=0'} />
                          }
                        </>
                      }
                    </EnrichedContainer>
                  )
                })
              }
            </Slider>
          </SliderContainer>
        }
      </Container>
    </Wrapper>
  );
}

EnrichedImageGallery.propTypes = {
  background: PropTypes.string,
  pretitle: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array
};

EnrichedImageGallery.defaultProps = {
  background: null,
  pretitle: null,
  title: null,
  data: []
}

export default EnrichedImageGallery;