import React from 'react';
import propTypes from 'prop-types';
import Text from '../../typography'
import ReactHtmlParser from 'react-html-parser';
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import {RespImg, Links} from '../../ui';

const Wrapper = styled.div`  


  ${props => (props.backgroundColor === 'dark' && props.colourTheme === 'light') && `
      
      h2, h3, p {
        color:#2E3238;
      }
    `
  } 

  ${props => (props.backgroundColor === 'light' && props.colourTheme === 'dark') &&
    `
      h2, h3, p, div {
        color:${theme.palette.white};
      }
      ` 
  };

  ${props => (props.backgroundColor === 'dark' && props.colourTheme === 'dark') &&
    `
      h2, h3, p, div {
        color:${theme.palette.white};
      }
      ` 
  };

  h6 {
    font-size:12px;
    line-height:16px;
    margin-bottom:5px;
    ${theme.breakpoints.up("md")} {
      font-size:14px;
      line-height:19px;
    }
  }
  h2 {
    font-size:18px;
    line-height:24px;
    margin-bottom:10px;
    max-width: 815px;
    margin-left: auto;
    margin-right: auto;

    ${theme.breakpoints.up("md")} {
      font-size:21px;
      line-height:24px;
      margin-bottom:15px;
    }
    ${theme.breakpoints.up("lg")} {
      font-size:34px;
      line-height:38px;
    }
  }
`

const Container = styled.div`  
  max-width:1120px;
  margin:0 auto;
`

const IconTextItem = styled.div`
  margin-top:30px;
  padding:0 20px;
  max-width:310px;
  margin-left:auto;
  margin-right:auto;
  h3 {
    font-size: 14px;
    line-height: 19px;
    margin-top:10px;
    margin-bottom:8px;
    ${theme.breakpoints.up("md")} {
      font-size: 20px;
      line-height: 27px;
      margin-top:15px;
      margin-bottom:12px;
    }
  }
  picture {
    width:60px;
  }
  .svg-container {
    display:none;
  }
`


const IconTextGrid = (props) => {
  const {
    background,
    preTitle,
    title,
    link,
    listing,
    colourTheme
  } = props

  console.log('icon text grid 123', props);

  return (
    <Wrapper colourTheme={colourTheme} backgroundColor={background}>
      <Container>
      {preTitle &&
        <Box px={2}>
          <Text variant="h6" colour="vividBlue" uppercase="true" align="center">{preTitle}</Text>
        </Box>
      }
      {title &&
        <Box px={2}>
          <Text variant="h3" element="h2" uppercase="true" align="center" weight={800} colour="charcoal">{title}</Text>
        </Box>
      }
        
      <Grid container spacing={0} direction={'row'} wrap="wrap" justify={'center'} align={'center'}>       
        {listing.map((item, index) => {
          return (
            <Grid item xs={6} lg={4}>              
                <IconTextItem>
                  {item.link.href ?
                    <Links href={item.link.href} svg={null}>
                      <RespImg 
                        {...item.image}
                        type="png"
                        lazyLoad={false}
                      />                
                      <Text variant="h3" uppercase="true" align="center">{item.title}</Text>
                      <Text variant="body1" align="center">{ReactHtmlParser(item.text)}</Text>
                    </Links>
                  : <>
                      <RespImg 
                        {...item.image}
                        type="png"
                        lazyLoad={false}
                      />                
                      <Text variant="h3" uppercase="true" align="center" colour="vividBlue">{item.title}</Text>
                      <Text variant="body1" align="center">{ReactHtmlParser(item.text)}</Text>
                  </>
                  }
                </IconTextItem>
            </Grid>
          )
        })}
      </Grid>
      </Container>
    </Wrapper>
  )
}
  
IconTextGrid.propTypes = {
  background: propTypes.string,
  preTitle: propTypes.string,
  title: propTypes.string,
  listing: propTypes.array.isRequired
}
  
IconTextGrid.defaultProps = {
  background: 'light',
  preTitle: null,
  title: null,
  listing: []
}
  
export default IconTextGrid