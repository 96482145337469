import React from 'react'
import propTypes from 'prop-types'
import Text from '../../typography'
import { theme } from '../../../themes/default-theme'
import styled from 'styled-components'
import RespImg from '../../ui/RespImg'
import ReactHtmlParser from 'react-html-parser'
import { Box, Grid } from '@material-ui/core'
import Calculator from '../../ui/Calculator'
import topo from '../../../assets/topo.png';

const Container = styled.div`
  min-height: 775px;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    height: 160px;
    z-index: -1;
    left: 0;
    bottom: -40px;
    display: block;
    background-image: url(${topo});
    background-size: 400px auto;
    background-repeat: repeat;
    background-position: center bottom;
    width: 100%;
    ${theme.breakpoints.up('md')} {
      bottom: -60px;
    }
  }

`

const Asset = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
      object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;

  ${theme.breakpoints.down('sm')} {
    padding-top: 60px;
    max-width: 392px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    margin-bottom: 8px;
  }
`

const CalculatorContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  iframe {
    height: 700px;
    width: 100%; 
    max-width: 394px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 15px;
    border: none;
  }
`

const TextContent = styled.div`
  padding-left: 20px;
  
  ${theme.breakpoints.up('mlgd')} {
    padding-left: 40px;
  }
  

`
  
const CalculatorBanner = ({
  title,
  subtext,
  image,
  priority,
  dealers,
  make,
  price,
  model,
  variant,
  dealerId
}) => {
  return (
  	<Container>

      <Content>
        <Grid container spacing={0} direction={'row'} wrap="wrap" justifyContent={'center'} alignItems={'center'}>       
          <Grid item xs={12} md={6} lg={8}> 
            <Box px={2}>
              <TextContent>
                {title &&
                  <Text variant="h1" colour="white" align="left" uppercase={true}>{ReactHtmlParser(title)}</Text>
                }
                {subtext &&
                  <Text variant="h6" colour="white" align="left" uppercase={true}>{ReactHtmlParser(subtext)}</Text>
                }
              </TextContent>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}> 
          
              <CalculatorContainer>
                <Calculator 
                  dealers={dealers} 
                  make={make}
                  price={price}
                  model={model}
                  variant={variant}
                  defaultDealerId={dealerId}
                  />
              </CalculatorContainer>
            
          </Grid>
        </Grid>

      </Content>
      <Asset>
        {image &&
          <RespImg 
            {...image}
            fetchPriority={priority}
            blur={true}
          />
        }
      </Asset>
    </Container>
  )
}
  
CalculatorBanner.propTypes = {
  title: propTypes.string,
  subtext: propTypes.string,
  image: propTypes.object,
  dealers: propTypes.array
}
  
CalculatorBanner.defaultProps = {
  priority: null,
  dealers: []
}
  
export default CalculatorBanner